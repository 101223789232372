.scrollable-items {
    max-height: 150px; /* Set the height you want */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .scrollable-items ul {
    padding-left: 20px; /* Add spacing to list items */
    list-style: none;
  }
  
  .scrollable-items li {
    padding: 5px 0; /* Add padding between list items */
  }
  
  .card {
    margin-bottom: 15px;
  }
  