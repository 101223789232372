/* Base Styling */
.main-page {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6; /* Light background color */
}

.name-fields {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Adjust the space between fields */
}

.name-fields .text-field {
  flex: 1; /* Make both text fields take equal width */
  max-width: 48%; /* Ensure the fields don't stretch too much */
}


/* Left section styles for larger screens */
.left-section {
  background-color: #ff5722; /* Bright orange background */
  color: white;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.amplify-alert{

  margin-bottom: 15px;
}
.links-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px; /* Adjust margin as needed */
}

.links-container a {
  color: #ff5722;
  text-decoration: none;
}

.links-container a:hover {
  text-decoration: underline;
}

.left-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.left-section p {
  font-size: 1rem;
  text-align: center;
}

.left-section .social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.left-section .social-icons a {
  color: white;
}

/* Right Section */
.right-section {
    color: white;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.login-form {
  width: 100%;
  max-width: 570px;
  padding: 20px;
  color: #333333;
}

.login-form h2 {
  color: #333333;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.subtitle {
  color: #666666;
  font-size: 1rem;
  margin-bottom: 20px;
}

.text-field input {
  padding: 18px;
  line-height: 22px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.text-field input:focus {
  border-color: #ff5722;
  outline: none;
  box-shadow: 0 0 4px rgba(255, 87, 34, 0.5);
}

.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.remember-me input {
  margin-right: 10px;
}

.links-container {
  text-align: right;
  margin-top: 10px;
}

.links-container a {
  color: #ff5722;
  text-decoration: none;
  font-size: 0.9rem;
}

.links-container a:hover {
  text-decoration: underline;
}

.divider {
  border: 0;
  height: 1px;
  background: black;
  margin: 20px 0;
}

.margin-top{
  margin-top: 20px;
}
.secondary {
  background-color: #ffffff;
  color: #ff5722;
  border: 1px solid #ff5722;
}

.secondary:hover {
  background-color: #ffece4;
}


.auto-search-mapbox {

  background-color: #f9f9f9; /* Optional background for better visual distinction */
 
}

.auto-search-mapbox .city-field {
  position: relative;
  margin-top: 20px;
}


.auto-search-mapbox .text-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.auto-search-mapbox .text-field:focus {
  outline: none;
  border-color: #3b82f6; /* Use a distinct color to indicate focus */
}

.auto-search-mapbox .suggestions-list {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ddd;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
}

.auto-search-mapbox .suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.auto-search-mapbox .suggestion-item:hover {
  background-color: #f1f1f1;
}



/* For screens less than 768px (mobile devices) */
@media screen and (max-width: 768px) {
  .main-page {
      flex-direction: column; /* Stack elements vertically */
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 20px;
  }

  /* Hide the left section on mobile */
  .left-section {
      display: none;
  }

  /* Right section takes full width */
  .right-section {
      width: 100%;
      justify-content: center;
  }

  /* Adjust login form width for smaller screens */
  .login-form {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
  }

  /* Social icons move to the bottom of the form on mobile */
  .login-form .social-icons {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 30px; /* Add space between form and social icons */
  }

  /* Social icons size on mobile */
  .login-form .social-icons img {
      width: 30px;
      height: 30px;
  }
}
