.form-container {
  margin: 50px auto; /* Margin from all sides */
  background-color: #f9f9f9; /* Light background color */
  padding: 30px; /* Padding inside the form */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for better visibility */
  max-width: 900px; /* Set a max-width for the form */
}

.stepper-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
  }
  
.stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .stepper-item .step-counter {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  .stepper-item.active .step-counter {
    background-color: #f45c43; /* Active step color */
  }
  
  .stepper-item .step-name {
    margin-top: 8px;
    font-size: 12px;
    color: #555;
  }
  
  .stepper-item.active .step-name {
    color: #f45c43; /* Active step text color */
  }
  
  /* Connecting line */
  .stepper-wrapper::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #e0e0e0;
    z-index: 0;
  }
  
  .stepper-item.active ~ .stepper-item .step-counter {
    background-color: #e0e0e0;
  }
  
  .stepper-item.active ~ .stepper-item .step-name {
    color: #e0e0e0;
  }
  /* Branches.css */
  .mapbox-container {
    width: 100%;
    height: 400px; /* Ensure a height is set, adjust this as needed */
    border: 1px solid #ccc;
    position: relative;
  }
  .alert-margin-top{
    margin-top: 20px;
  }
  
