.location-logo{
  height:65px;
  width: auto;
}

/* Base Styling */
.signup-button {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    margin-top: 10px;
  }
  .button-style {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
  }
  
  .signup-button:hover {
    background-color: #e64a19;
  }
  
.sign-up-page {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
  }
  
  /* Left section styles */
  .left-sections {
    color: #333;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .left-section h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .left-section p {
    font-size: 1rem;
    text-align: center;
  }
  
  .left-section .social-icons {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }
  
  .left-section .social-icons img {
    width: 40px;
    height: 40px;
  }
  
  /* Right section containing the sign-up form */
  .right-section {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sign-up-form {
    /* background-color: #fff; */
    background-color: transparent;
    padding: 40px;
    border-radius: 10px;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
    box-shadow: none;
    width: 70%;
  }
  
  .sign-up-form h2 {
    color: #ff5722;
    margin-bottom: 20px;
    font-size: 2rem;
    text-align: left;
  }
  
  .sign-up-form .text-field {
    margin-bottom: 20px;
  }
  
  .sign-up-form .text-field label {
    font-size: 1rem;
  }
  
  .sign-up-form .login-link {
    margin-top: 20px;
  }
  
  .sign-up-form .login-link a {
    color: #ff5722;
    text-decoration: none;
  }
  
  buttons {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }
  
  buttons:hover {
    background-color: #e64a19;
  }
  
  /* Responsive Styling */
  @media screen and (max-width: 768px) {
    .sign-up-page {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 20px;
    }
  
    .left-section {
      display: none;
    }
  
    .right-section {
      width: 100%;
    }
  
    .sign-up-form {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  /* SignUpPage.css */

.button-style {
  position: relative;
  /* Add other styling properties */
}

.button-style:disabled {
  background-color: #d0d0d0;
  cursor: not-allowed;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* MAP CSS */
.map-container {
  width: 100%;
  height: 100vh; /* Adjust based on your layout */
}
.map-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.map-layers {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.map-layers button {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}

.map-layers button:hover {
  background-color: #eee;
}

.fixed-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 32px;
  pointer-events: none; /* Makes sure the pin doesn't interfere with map interaction */
}

  