/* Modal styling */
.settings-modal-custom .modal-header {
    background-color: #D9F3EA; /* Custom header color */
    color:  hsl(190, 95%, 30%);
    border-bottom: none;
}

.settings-modal-custom .nav-pills .nav-link.active {
    background-color: #D9F3EA; /* Custom header color */
    color:  hsl(190, 95%, 30%);
}

.modal {
    background: rgba(255, 255, 255, 0.7);
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
  }
.settings-modal-custom .modal-title {
    font-size: 1.5rem;
}

.settings-modal-custom .modal-body {
    padding: 30px;
    background-color: #f4f7fc; /* Light background */
}

.settings-modal-custom .modal-footer {
    border-top: none;
    padding: 20px;
    background-color: #e9ecef; /* Light grey footer */
}

.settings-modal-custom .custom-tab {
    padding: 10px 20px;
    color: #6c757d; /* Tab color */
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.settings-modal-custom .custom-tab .tab-icon {
    margin-right: 10px;
}

.settings-modal-custom .custom-tab:hover, .settings-modal-custom .custom-tab.active {
    background-color: #D9F3EA;
    color: hsl(190, 95%, 30%);
}

.settings-modal-custom .tab-title {
    color: #343a40; /* Dark text color for titles */
    font-size: 1.25rem;
    margin-bottom: 20px;
}

/* Custom select */
.settings-modal-custom .custom-select {
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    font-size: 1rem;
}

/* Custom button styles */
.settings-modal-custom .custom-button {
    background-color: #238d9d;
    border-color: #238d9d;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.settings-modal-custom .custom-button:hover {
    background-color: #1c757f;
}

.settings-modal-custom .custom-button-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.settings-modal-custom .custom-button-secondary:hover {
    background-color: #5a6268;
}

/* Custom switch */
.settings-modal-custom .custom-switch .form-check-input {
    cursor: pointer;
}
