.menu-container {
  padding: 20px;
}

.category-section {
  margin: 40px 0;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
}

.item-card {
  padding: 15px;
  width: calc(50% - 20px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  transform-origin: center center;
}

.item-card img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 15px;
}

.item-details {
  display: flex;
  flex-direction: column;
}

.item-details h3 {
  font-size: 18px;
  margin: 0 0 5px;
}

.item-details p {
  margin: 0;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  padding: 10px 0;
}

.menu-category-item h6 a {
  color: #092c4c;
  font-weight: bold;
  text-decoration: none;
}

.search-container {
  flex-grow: 1;
  padding-right: 20px;
}

.search-box {
  width: 100%;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
}

.category-scroll {
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
}

.category-list {
  display: flex;
}

.category-item {
  margin: 0 10px;
  padding: 10px 15px;
  cursor: pointer;
}

.category-item.active {
  border-bottom: 2px solid black;
}

.arrow {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0 10px;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.add-category-card {
  /* margin-top: 20px; */
  width: calc(50% - 20px);
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border :2px dashed #007bff;

}

.add-category-card:hover {
  background-color: #e0e0e0;
}

.add-category-content h3 {
  margin: 0;
  display: flex;
  align-items: center; /* Align items vertically center */
}

.add-category-content p {
  margin: 5px 0 0;
}

.menu_tabs_wrapper .menu-category-item li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  font-family: sans-serif;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid;
}

.menu_tabs_wrapper .menu-category-item li.active {
  border-bottom: 3px solid #FF5722;
}

.menu-category-item span {
  color: #092c4c;
}

.menu_tabs_wrapper .menu-category-item li.active h6 a {
  color: #FF5722;
}

.menu_tabs_wrapper .menu-category-item li.active .menu-category-details {
  background: 0 0;
  border: 0 !important;
}

.menu_tabs_wrapper .menu-category-item li.active .menu-category-details h6 {
  color: #fff;
}

.menu_tabs_wrapper .menu_tabs_container .menu_tab_content {
  display: none;
  width: 100%;
}

.menu_tabs_wrapper .menu_tabs_container .menu_tab_content.active {
  display: inline-block;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.categories-top-bar {
  align-items: center;
}

.sticky-wrapper {
  position: -webkit-sticky;
  background-color: #fff;
  box-shadow: 0 14px 28px -12px rgba(0, 0, 0, .12);
  position: sticky;
  top: 60px;
  z-index: 100;
  padding: 10px 10px;
}

.menu-category-item {
  display: inline-block;
}

.menu_tabs_wrapper {
  position: relative;
  overflow: hidden;
}

.slick-arrow {
  z-index: 1;
}

.slick-prev {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.slick-next {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  color: black;
  line-height: 1;
}

.item-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.item-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .item-card {
    width: 100%;
  }

  .category-scroll {
    flex-direction: column;
  }

  .category-item {
    margin-bottom: 10px;
  }

  .item-modal {
    width: 90%;
  }
}

/* Accessibility Improvements */
.category-item:focus,
.slick-arrow:focus {
  outline: 2px solid #FF5722;
  outline-offset: 2px;
}
