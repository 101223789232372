/* Main Title */
.popup-title {
  font-weight: bold;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Section Headings */
.popup-heading {
  font-weight: bold;
  font-size: 18px;
  color: #007bff;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Text */
.popup-text {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

/* Divider Styling */
.popup-divider {
  margin: 20px 0;
  background-color: #ddd;
  height: 1px;
}

/* Variants List */
.variant-list {
  list-style: none;
  padding-left: 0;
}

.variant-list li {
  font-size: 16px;
  margin-bottom: 8px;
}

/* Add-on Group */
.addon-group {
  margin-bottom: 20px;
}

.addon-list {
  list-style: none;
  padding-left: 0;
}

.addon-list li {
  font-size: 16px;
  margin-bottom: 8px;
}

/* Button Styling */
.close-buttons {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.close-buttons:hover {
  background-color: #0056b3;
}
