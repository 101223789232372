

/* new header css */
.top-nav {
  justify-content: space-between !important;
    background-color: transparent;
    transition: background-color 0.3s ease;
    padding-left: 290px !important;
    padding-right: 1rem !important;
    
  }
  .dropdown-toggle::after{
   display: none !important;
  }
  
  .navbar-scrolled {
    background-color: #FFFFFF ; /* The new color when scrolled */
    transition: background-color 0.3s ease;
  }
  .navbar-scrolled form .input-group  .search-bar{
    background-color: #D5E5F3 !important; /* The new color when scrolled */
    transition: background-color 0.3s ease;
  }
  .search-bar{
    border: none !important;
  }
  .search-bar:focus{
    box-shadow: none !important;
  }
  .nav-search-icon{
    background-color: #fff !important;
    border: none !important;
    color: gray;
  }
  .navbar-scrolled .navbar-brand,
  .navbar-scrolled .nav-link {
    color: #fff; /* Optional: Change text color when navbar is scrolled */
  }
  

.icon-container {
    position: relative;
  }
  
  .badge-notification {
    position: absolute;
    top: -5px;
    right: -10px;
    font-size: 12px;
  }
  
  .icon-container {
    margin-right: 20px;
    padding: 10px;
    background: #D5E5F3;
    border-radius: 14px;
    color: #2C9CDB;
    cursor: pointer;
  }
  
  .profile-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
  }

  /* Notifications Dropdown Styling */
.notification-dropdown {
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  top: 40px;
  right: 0;
  width: 250px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
}

.notification-dropdown .dropdown-header {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem;
  background-color: #f8f9fa;
}

.notification-dropdown .list-group-item {
  font-size: 0.9rem;
  padding: 1rem;
}

.notification-dropdown .dropdown-item {
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
  color: #007bff;
}
  
  @media only screen and (max-width: 600px) {
    .top-nav {
      justify-content: right !important;
      padding-left: 0 !important;
      padding-right: 1rem !important;
      z-index: 0 !important;
    }
    .top-nav form{

        display: none !important;
    }

  }