/* General Container and Row */
.container-fluid.p-4 {
    padding: 2rem;
  }
  
  .align-items-center.mb-4 {
    margin-bottom: 1.5rem;
  }
  
  /* Top Section: Order ID, Buttons, and Dropdown */
  h5 {
    font-weight: bold;
    color: #333;
  }
  
  .text-right.d-flex.justify-content-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  button.btn-danger {
    background-color: transparent;
    border: none;
    width: 150px;
    color: #BB2D3B;
    border: 1px solid #BB2D3B;
  }
  
  button.btn-success {
    background-color: #01B075;
    border: none;

  }
  
  /* Left Side: Profile and Notes */
  .Customer-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 30px 30px 0 0;
  }
  
  .Customer-card img {
    width: 100px;
    height: 100px;
  }
  
  .Customer-card .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .Customer-card .card-subtitle {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .note-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: -14px !important;
    border: none !important;
    border-radius: 0 !important;
    background: #5E6C93 !important;
    color: #fff;
  }
  
  .note-card .card-header {
    color: white !important;
    font-weight: bold;
    font-weight: bold;
    background: #5E6C93 !important;
    border: none !important;
  }
  .note-card .card-body .card-text{
    color: #fff;
  }
  .note-card .fa-map-marker {
    color: #007bff;
    margin-right: 10px;
  }
  .note-card .list-group .list-group-flush .list-group-item{
    padding: 20px;
    border-radius: 10px;
}
/* Update Table Header Color */

.order-table .card-header{
    background-color: #01B075;
}
.order-table thead th {
    background-color: #01B075; /* Change to your desired color */
    color: #fff; /* Change to your desired text color */
  }
  
  /* Style for Item Image and Text */
  .item-info {
    display: flex;
    align-items: center;
  }
  
  .item-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 1rem; /* Adjust space between image and text */
    border-radius: 10px;
  }
  
  /* Adjust the contact-info styles if needed */
  .contact-info {
    display: flex;
    margin-top: 1rem;
  }
  
  .contact-info p {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: #333;
  }
  
  .contact-info .fa {
    font-size: 1.2rem;
    color: #007bff; /* or any other color */
  }
  

  /* History Section */
  .history-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .history-card .card-header {
    background-color: #fff;
    color: #000;
    font-weight: bold;
  }
  
  /* Right Side: Order Table */
  .order-table {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .order-table th {
    background-color: #007bff;
    color: white;
    text-align: center;
  }
  
  .order-table td {
    text-align: center;
    padding: 1rem;
  }
  
  .order-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Track Orders Section */
  .track-order {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .track-order h5 {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .track-order .fa-truck {
    color: #007bff;
  }
  
  .track-order .d-flex.align-items-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .track-order .rounded-circle {
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .track-order strong {
    font-size: 1.1rem;
  }
  
  .track-order p {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
  .contact-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 10px 15px;

    font-family: "Montserrat", sans-serif;
  }
  .contact-info div{

    border: 2px solid #f05a00; /* Orange border */
    border-radius: 8px;
    padding: 10px 15px;
    width: fit-content;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Montserrat", sans-serif;
  }
  .contact-info .phone-icon {
    font-size: 20px;
    color: #f05a00;
    margin-right: 10px;
  }
  
  .contact-info p {
    font-size: 16px;
    font-weight: 500;
    color: #2c3e50;
    margin: 0;
  }
  
  /* You can also style hover effects */
  .contact-info:hover {
    border-color: #2c3e50;
    background-color: #fefefe;
  }
  /* order history new */
  .order-track {
    padding: 0 1rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
  }
  .track-card .card-header {
    color: #000 !important;
    font-weight: bold;
    font-weight: bold;
    background-color: #fff;
    border: none !important;
  }
  .order-track-step {
    display: flex;
    height: 5rem;
  }
  
  .order-track-step:last-child {
    overflow: hidden;
    height: 4rem;
  }
  
  .order-track-step:last-child .order-track-status span:last-of-type {
    display: none;
  }
  
  .order-track-status {
    margin-right: 1.5rem;
    position: relative;
  }
  
  .order-track-status-dot {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #f05a00;
  }
  
  .order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #f05a00;
  }
  
  .order-track-text-stat {
    font-weight: 500;
    margin-bottom: 3px;
  }
  
  .order-track-text-sub {
    font-size: 1rem;
    font-weight: 300;
  }
  
  .order-track {
    transition: all 0.3s;
    transform-origin: top center;
  }