
.bold-header {
    font-weight: bold;
  }
  .search-input {
    margin: 10px; /* Adjust the margin as needed */
  }
  .css-5wly58-MuiDataGrid-root{
    background-color: white;
  }
  /* Add these CSS styles to your Item.css file or your preferred CSS file */
/* .delete-button {
    background-color: #dc3545; 
    color: #fff; 
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #c82333; 
  } */
 /* Default styles for close button */
.close-buttons {
  background-color: #fd6a65; /* Darker red background color on hover */

  color: white; /* White text color */
  border: none;
  padding: 10px 44px;
  border-radius: 5px;
  cursor: pointer;
}

/* Hover styles for close button */
.close-buttons:hover {
  background-color: #d9534f; /* Darker red background color on hover */
}
/* Item.css */
.confirm-button {
  background-color: #ff4d4f; /* Red color for Yes button */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.cancel-button {
  background-color: #1890ff; /* Blue color for No button */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.search-container {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.enhanced-search {
  width: 100%;
  padding: 10px 40px 10px 35px; /* Padding for the icons */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: all 0.3s ease;
}

.search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #aaa;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #aaa;
  font-size: 18px;
  cursor: pointer;
}

.clear-button:hover {
  color: #ff0000; /* Optional hover effect for cross button */
}

.enhanced-search:focus {
  border-color: #007bff;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
}

.search-input::placeholder {
  color: #aaa;
  font-style: italic;
}





.pagination-controls {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  font-size: 14px;
  color: #333;
  height: 45px;
}

.pagination-label {
  font-weight: bold;
  color: #555;
}

.pagination-select {
  margin: 0 10px;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
/* Modal overlay */
.category-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above other elements */
}

/* Modal content */
.category-popup {
  background-color: #fff;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensure it is above overlay */
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.popup-submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-close-button {
  background-color: #ccc;
  color: #333;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}



