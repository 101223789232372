.tiles-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .dashboard-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 22%; /* Adjust width to fit 4 cards in a row */
  }
  
  .dashboard-tile-icon img {
    width: 50px;
    height: 50px;
  }
  
  .dashboard-tile-content {
    text-align: center;
  }
  
  .dashboard-tile-number {
    font-size: 40px;
    font-weight: bold;
  }
  
  .dashboard-tile-text {
    font-size: 18px;
    color: #666;
  }
  
  .dashboard-tile-percentage {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    color: #999;
  }
  
  .dashboard-tile-percentage.up {
    color: green;
  }
  
  .dashboard-tile-percentage.down {
    color: red;
  }
  
  .dashboard-tile-percentage span:first-child {
    margin-right: 5px;
    font-size: 16px;
  }
  