.pos-products .product-info {
    padding: 15px;
    color: #b8bcc9;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    cursor: pointer;
}
.pos-products .product-info:hover, .pos-products .product-info.active {
    border-color: #FF5722;
}
.pos-products .product-info:hover .img-bg span, .pos-products .product-info.active .img-bg span {
    display: block;
}
.slick-prev:before, .slick-next:before{
    color: black !important;
}
.slick-next{
    right: 0 !important;
}
.pos-category h6 a{
    color: #092c4c;
    font-weight: bold;
    text-decoration: none;
}
.pos-category span{
    color: #092c4c;
}

.pos-products .product-info .img-bg {
    height: 117px;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-bottom: 10px;
    position: relative
}

.pos-products .product-info .img-bg img {
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease
}

.pos-products .product-info .img-bg span {
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    position: absolute;
    top: 50px;
    right: -35px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #28c76f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    display: none;
}
.feather-16 {
    width: 16px;
    height: 16px
}
.feather-16.icon {
    position: absolute;
    top: 10px;
    right: 10px
}
.pos-products .product-info .img-bg span svg {
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px
}

.pos-products .product-info h6 {
    font-size: 15px;
    font-weight: 700
}

.pos-products .product-info h6.cat-name a {
    color: #b8bcc9;
    font-size: 12px;
}

.pos-products .product-info h6.product-name a {
    color: #092c4c;
   
}

.pos-products .product-info .price {
    margin-top: 17px
}

.pos-products .product-info .price span {
    color: #7367f0
}

.pos-products .product-info .price p {
    color: #ff9f43
}

.pos-products .product-info:hover,
.pos-products .product-info.active {
    border-color: #FF5722
}

.pos-products .product-info:hover .img-bg img,
.pos-products .product-info.active .img-bg img {
    transform: scale(1.2)
}

.pos-products .product-info:hover .img-bg span,
.pos-products .product-info.active .img-bg span {
    display: block
}
.tabs_wrapper ul.tabs {
    display: inline-block;
    width: 100%;
    padding-left: 0
}

.tabs_wrapper ul.tabs li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    color: #fff;
    font-family: sans-serif;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid;
}

.tabs_wrapper ul.tabs li.active {
border-bottom: 3px solid #FF5722;
}

.tabs_wrapper ul.tabs li.active h6 a {
    color: #FF5722;
}

.tabs_wrapper ul.tabs li.active .product-details {
    background: 0 0;
    border: 0 !important
}

.tabs_wrapper ul.tabs li.active .product-details h6 {
    color: #fff
}


.tabs_wrapper .tabs_container .tab_content {
    display: none;
    width: 100%
}

.tabs_wrapper .tabs_container .tab_content.active {
    display: inline-block
}

.product-wrap .product-list .info span {
    margin-bottom: 7px;
    display: inline-block
}
.sticky-aside {
    position: sticky;
    top: 60px;
    z-index: 999;
  }
.product-wrap .product-list .info h6 a {
    margin-bottom: 5px;
    display: inline-block
}
.product-wrap {
    height: 34vh;
    overflow: auto
}

.product-wrap .product-list {
    box-shadow: 0 4px 60px 0 rgba(231, 231, 231, .47);
    margin: 0 0 10px;
    padding: 10px
}

.product-wrap .product-list .product-info {
    width: 60%
}

.product-wrap .product-list .product-info .img-bg {
    width: 83px;
    height: 83px;
    background-color: #fafbfe;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-right: 10px
}

.product-wrap .product-list .info span {
    background-color: #FF5722;
    border-radius: 3px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
    min-width: 64px
}

.product-wrap .product-list .info h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0
}

.product-wrap .product-list .info h6 a {
    color: #092c4c
}

.product-wrap .product-list .info p {
    font-size: 15px;
    font-weight: 600;
    color: #5b6670
}

.product-wrap .product-list .qty-item {
    position: relative;
    margin-right: 7px
}

.product-wrap .product-list .qty-item input {
    padding: 1px 9px;
    background: #fafbfe;
    border-radius: 8px;
    height: 28px;
    width: 71px;
    text-align: center;
    font-size: 14px
}

.product-wrap .product-list .qty-item .dec,
.product-wrap .product-list .qty-item .inc {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    color: #092c4c;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease
}

.product-wrap .product-list .qty-item .dec:hover,
.product-wrap .product-list .qty-item .inc:hover {
    color: #ff9f43
}

.product-wrap .product-list .qty-item .dec {
    left: 9px
}

.product-wrap .product-list .qty-item .inc {
    right: 9px
}

.product-wrap .product-list .action a {
    padding: 7px;
    border-radius: 8px;
    border: 1px solid #f3f6f9;
    cursor: pointer
}

.product-wrap .product-list .action a.edit-icon {
    color: #3577f1
}

.product-wrap .product-list .action a.edit-icon:hover {
    background-color: #3577f1;
    border-color: #3577f1;
    color: #fff
}

.product-wrap .product-list .action a.delete-icon {
    color: red
}

.product-wrap .product-list .action a.delete-icon:hover {
    background-color: red;
    border-color: red;
    color: #fff
}

aside {
    height: 100%;
    padding: 24px;
    background-color: #fff;
    border-left: 1px solid #f3f6f9
}

aside.product-order-list h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1b2850
}

aside.product-order-list .block-section {
    margin: 20px 0;
    padding: 0 0 20px;
    border-bottom: 1px solid #f3f6f9
}

aside.product-order-list .head {
    background-color: #fafbfe;
    border-radius: 8px;
    padding: 10px
}

aside.product-order-list .head h5 {
    color: #092c4c;
    font-size: 18px;
    font-weight: 700
}

aside.product-order-list .head span {
    color: #5b6670;
    font-size: 15px;
    font-weight: 500
}

aside.product-order-list .customer-info .input-block {
    margin-bottom: 10px
}

aside.product-order-list .customer-info .input-block .btn {
    min-width: 40px;
    min-height: 40px;
    margin: 0 0 0 10px;
    padding: 5px
}

aside.product-order-list .product-added .head-text {
    margin-bottom: 20px
}

aside.product-order-list .product-added .head-text .trash svg {
    margin-right: 8px
}

aside.product-order-list .product-added h6 .count {
    width: 15px;
    height: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background: #FF5722;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    margin: 0 0 0 7px
}

aside.product-order-list .selling-info {
    margin-bottom: 24px
}

aside.product-order-list .selling-info .input-block label {
    margin-bottom: 5px
}

aside.product-order-list .selling-info .input-block .select2-container--default .select2-selection--single .select2-selection__rendered {
    border-radius: 5px;
    border-color: #353570
}

aside.product-order-list .order-total {
    background-color: #f3f6f9;
    padding: 24px;
    border-radius: 8px
}

aside.product-order-list .order-total table tr td {
    padding: 0 0 15px;
    color: #5b6670;
    font-size: 15px;
    font-weight: 500;
    background: 0 0
}

aside.product-order-list .order-total table tr td.danger {
    color: red
}

aside.product-order-list .order-total table tr:last-child td {
    padding: 20px 0 0;
    font-size: 16px;
    font-weight: 600
}

aside.product-order-list .payment-method .methods .item {
    padding: 0 7px
}

@media(max-width:991.98px) {
    aside.product-order-list .payment-method .methods .item {
        padding-bottom: 15px
    }
}

aside.product-order-list .payment-method .methods .default-cover {
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease
}

aside.product-order-list .payment-method .methods .default-cover a {
    box-shadow: 0 4px 60px 0 rgba(190, 190, 190, .27);
    padding: 10px 15px;
    text-align: center;
    display: block;
    font-size: 15px;
    color: #092c4c
}

aside.product-order-list .payment-method .methods .default-cover a span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block
}

aside.product-order-list .payment-method .methods .default-cover:hover {
    background-color: #eff2f7;
    border-color: #ff9f43
}

aside.product-order-list .payment-method .methods .default-cover:hover img {
    filter: invert(75%) sepia(66%) saturate(1955%) hue-rotate(327deg) brightness(103%) contrast(102%)
}

aside.product-order-list .btn-block {
    margin: 20px 0;
    padding: 0 0 20px;
    border-bottom: 1px solid #f3f6f9
}

aside.product-order-list .btn-block .btn {
    font-size: 14px;
    font-weight: 700;
    padding: 13px 10px
}

aside.product-order-list .btn-row {
    padding: 0
}

aside.product-order-list .btn-row .btn {
    margin-right: 5px;
    border-radius: 4px;
    font-size: 14px
}

aside.product-order-list .btn-row .btn.btn-info {
    background-color: #7367f0;
    border-color: #7367f0
}

aside.product-order-list .btn-row .btn.btn-danger {
    background-color: red;
    border-color: red
}

aside.product-order-list .btn-row .btn.btn-success {
    background-color: #28c76f;
    border-color: #28c76f
}

aside.product-order-list .btn-row .btn:hover {
    background-color: #092c4c;
    border-color: #092c4c
}
.payment-section {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: #fff;
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: right 0.7s ease-in-out;
}

.payment-section.slide-in {
  right: 0; /* Slide-in when visible */
}

.payment-section.slide-out {
  right: -100%; /* Slide-out when hidden */
}
  
  .payment-content {
    padding: 20px;
    overflow-y: auto;
  }
  
  .btn-icon {
    cursor: pointer;
  }
  

.payment-content {
    padding: 20px;
}

.payment-content input {
    width: 100%;
    margin: 10px 0;
}
.default-cover a {
    text-decoration: none;
    padding: 10px 15px;
    text-align: center;
    display: block;
    font-size: 15px;
    color: #092c4c;
}

.pos-modal .modal-header h5 {
    margin-bottom: 0
}

.modal button.close {
    font-size: 20px
}

.modal button.close:hover {
    background: red
}

.custom-modal-header .page-title h4 {
    margin-bottom: 0
}

.card-img-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -50px; /* Adjust to lift the image above the card */
  }
  
  .card-img {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: add shadow to the image */
  }
  