/* OnTheWayOrders.css */

.orders-container {
  width: 25%; /* Set the width of the container */
  padding: 20px; /* Add padding */
  border-left: 1px solid black; /* Add left border */
}

.card-custom {
  cursor: pointer; /* Pointer cursor on hover */
  width: 260px; /* Set card width */
  height: 120px; /* Set card height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  border-radius: 4px; /* Rounded corners */
}

.card-body {
 padding: 0px;
}

.card-title-custom {
  font-size: 1.2rem; /* Font size for title */
}

.card-text-custom {
  margin-bottom: 5px; /* Adjust bottom margin */
}

.card-price-custom {
  font-weight: bold; /* Bold price text */
}

/* Reduce margin between cards */
.mb-3 {
  margin-bottom:1px; /* Decrease margin between cards */
}
.orders-container {
  height: 100vh; /* Full height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.card-custom {
  cursor: pointer;
  margin-bottom: 1rem;
}

.card-title-custom {
  font-weight: bold;
}

.card-price-custom {
  color: #333;
}
@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.shimmer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shimmer-card {
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0 0px, #e0e0e0 40px, #f0f0f0 80px);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 8px;
  height: 120px;
  width: 100%;
}

.shimmer-text {
  height: 10px;
  background: #ddd;
  margin: 5px 0;
  border-radius: 4px;
}
