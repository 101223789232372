.items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.dropzone {
  border: 2px dashed #007bff; /* Bootstrap primary color */
  border-radius: 5px;
  background-color: #ffffff; /* White background */
  text-align: center;
  transition: background-color 0.3s;
}

.dropzone.active {
  background-color: #e9ecef; /* Light gray on active */
}

.image-preview {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.card {
  width: 100%;
  max-width: 600px; /* Limit the card width */
  padding: 20px; /* Add some padding to the card */
}
