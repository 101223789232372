.choices-group-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .choices-group-popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .popup-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .popup-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .group-type-toggle {
    display: flex;
    margin-bottom: 15px;
  }
  
  .toggle-button {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    background: #f9f9f9;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .toggle-button.active {
    background: #007bff;
    color: #fff;
  }
  
  .force-values-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .force-input label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .force-input input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .popup-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .popup-close-button,
  .popup-submit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-close-button {
    background: #ccc;
  }
  
  .popup-submit-button {
    background: #28a745;
    color: #fff;
  }
  