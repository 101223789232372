.tiles{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.dashboard-card {
    width: 337.24px; 
    height: 172px; 
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-content {
    display: flex;
    align-items: center;
    width: 197.37px;
    height: 99px; 
  }
  
  .icon-wrapper {
    width: 72px; 
    height: 72px;
    margin-right: 20px;
  }
  
  .icon-image {
    width: 100%; 
    height: 100%;
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .card-number {
    font-size: 45px; 
    font-weight: bold;
    color: #464255;
    ;
  }
  
  .card-text {
    font-size: 16px;
    color: #666;
    margin-top: 5px;
  }
  
  .card-percentage {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 8px;
    color: #A3A3A3;;
  }
  
  .card-percentage span {
    margin-left: 5px; 
  }
  
  .up-arrow-icon {
    width: 14px; 
    height: 14px;
  }
  
  /* --- MEDIA QUERIES --- */

/* (4 cards in a row */
@media (min-width: 1200px) {
    .tiles {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
  
  /* 3 cards in a row */
  @media (max-width: 1199px) and (min-width: 992px) {
    .tiles {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  
    .dashboard-card {
      width: 30%; 
    }
  }
  
  /* 2 cards in a row */
  @media (max-width: 991px) and (min-width: 768px) {
    .tiles {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  
    .dashboard-card {
      width: 45%; 
    }
  }
  
  /* 1 card per row */
  @media (max-width: 767px) {
    .tiles {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .dashboard-card {
      width: 100%; 
      max-width: 337px; 
    }
  }