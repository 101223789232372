.user-table-img {
    border-radius: 50%;
}
/* styles.css */
.view-button {
    background-color: #3498db; /* Set the background color */
    color: #fff; /* Set the text color to white */
    border: none; /* Remove the border */
    padding: 10px 10px; /* Add padding for spacing */
    cursor: pointer; /* Add a pointer cursor on hover */
    border-radius: 5px; /* Add rounded corners */
    font-size: 16px; /* Adjust the font size */
  }
  
  .view-button:hover {
    background-color: #2980b9; /* Change the background color on hover */
  }
  .status-indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .view-button {
    background-color: #007bff;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .view-button:hover {
    background-color: #0056b3;
  }
  .status-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
  
  .view-button {
    background-color: #007bff;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .view-button:hover {
    background-color: #0056b3;
  }
  
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Aligns the text and pagination vertically */
    margin-right: 20px; /* Optional: spacing from the right */
    margin-top: 15px;
  }
  
  .pagination-info {
    margin-right: 15px; /* Adds space between the text and the pagination */
  }
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: #ffff;
  }
  .search-group {
    max-width: 400px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .search-icon {
    /* background-color: #007bff; */
    color: white;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  
  .search-input {
    border: none;
    border-radius: 0;
    transition: box-shadow 0.3s ease;
  }
  
  .search-input:focus {
    box-shadow: none;
    outline: none;
  }
  
  .btn-clear {
    background-color: transparent;
    border: none;
    color: #007bff;
    padding: 0 10px;
    border-top-right-radius: 25px;
    /* border-bottom-right-radius: 25px; */
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .btn-clear:hover {
    color: #dc3545;
    transform: scale(1.1);
  }
  
  .search-group:hover .search-input {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .search-group .input-group-text {
    padding-left: 15px;
    padding-right: 15px;
  }
  .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {

margin-left: 14px;

  }