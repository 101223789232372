/* Shimmer effect CSS */
.shimmer {
    background-color: #f6f7f8;
    background-image: linear-gradient(90deg, #f6f7f8 25%, #e0e0e0 50%, #f6f7f8 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  .shimmer-item {
    width: 100px;
    height: 40px;
    margin: 5px 0;
    border-radius: 5px;
  }
  