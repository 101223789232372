/* Allresturents.css */

/* Container for the whole restaurant component */
.restaurant-container {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

/* Apply a backdrop blur when the modal is open */
.blur-background {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Keep the blur behind the content */
}

/* Styling for individual restaurant card */
.restaurant-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.restaurant-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.restaurant-card h3,
.restaurant-card p {
  margin-bottom: 12px;
  font-size: 1.1rem;
  color: #333;
}

.open-branch-button {
  margin-top: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 18px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.open-branch-button:hover {
  background-color: #0056b3;
}

/* Modal title and description styling */
.restaurant-container h4 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif; /* Clean, modern font */
}

.restaurant-container .MuiTypography-body1 {
  color: #555;
  font-size: 1.125rem;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.6;
}

/* Responsive grid layout for restaurant cards */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive cards */
  gap: 20px;
}

/* Grid item to center the cards */
.MuiGrid-item {
  display: flex;
  justify-content: center;
}

/* Error message styling */
.error-message {
  font-size: 1.125rem;
  font-weight: 500;
  color: #e74c3c;
  text-align: center;
  margin-top: 20px;
}
