/* GoToTopButton.css */
.go-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .scroll-to-top {
    background-color: #f05a00;
    border: none;
    border-radius: 50%;
    color: white;
    padding: 10px 15px;
    font-size: 18px;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .scroll-to-top:hover {
    background-color: #e04e00;
  }
  